import React from 'react';
import Body from './components/home';
import Nav from './screen/nav';
import ReactGA from 'react-ga';
export default function App() {
  return (
    <div>
      <Nav />
      
    </div>
  );
}

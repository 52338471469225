import React from 'react';
import ReactDOM from 'react-dom';
import App from './Analy';
import { BrowserRouter, Route } from 'react-router-dom';
ReactDOM.render(
  <BrowserRouter>
   <App/>
  </BrowserRouter>,
  document.getElementById('root')
);

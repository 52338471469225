import React,{ useEffect} from 'react'
import ReactGA from 'react-ga'
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom"
  import Home from'./components/home'
  import Cv from'./components/cv'
  import Privacy from'./components/Privacy'
  import App from'./App'
import Footer from './components/footer';


function Analy() {
    useEffect(() => {
        ReactGA.initialize('G-Q3RZ0EL9V1');
        // To Report Page View 
        ReactGA.pageview("/");
      }, [])
    return (
        <div>
            <App/>
            <Routes>
        <Route exact path="/cv" element={
          <Cv />
        }/>
        <Route exact path="/" element={<Home />}/>
          

        <Route exact path="/privacy" element={<Privacy />}/>
          
      </Routes>
      <Footer />

        </div>
    )
}

export default Analy
